import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import {
	faReceipt,
	faStickyNote,
	faBook,
	faBox,
	faFileAlt,
	faChartBar,
	faFileImport,
	faFileExport,
	faLanguage,
	faLock,
	faSignOutAlt,
	faClipboard,
} from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import Spinner from 'Components/spinner';
import FullMenuButton from './components/FullMenuButton.jsx';
import ModalLanguage from './components/ModalLanguage.jsx';
import ModalPassword from './components/ModalPassword.jsx';
import styles from './PageFullMenu.less';

class PageHome extends PureComponent {
	constructor() {
		super();
		this.state = {
			showModalLanguage: false,
			showModalPassword: false,
		};
		this.onToggleModalLanguageHandler = this.onToggleModalLanguageHandler.bind(this);
		this.onToggleModalPasswordHandler = this.onToggleModalPasswordHandler.bind(this);
		this.menu = [
			{
				id: 'salesOrder',
				icon: faReceipt,
				handler: this.onClickHandler.bind(this, 'sales-order/salesman'),
				permission: 'shop_front_sales_order',
			},
			{
				id: 'serviceMemo',
				icon: faStickyNote,
				handler: this.onClickHandler.bind(this, 'service-memo/salesman'),
				permission: 'shop_front_service_memo',
			},
			{
				id: 'stockCheck',
				icon: faBox,
				handler: this.onClickHandler.bind(this, 'stock-check'),
				permission: 'shop_front_stock_check',
			},
			{
				id: 'orderSearch',
				icon: faFileAlt,
				handler: this.onClickHandler.bind(this, 'order/search'),
				permission: 'shop_front_order_search',
			},
			{
				id: 'report',
				icon: faChartBar,
				handler: this.onClickHandler.bind(this, 'report'),
				permission: 'shop_front_report',
			},
			{
				id: 'stockTransferIn',
				icon: faFileImport,
				handler: this.onClickHandler.bind(this, 'stock-transfer/in'),
				permission: 'shop_front_stock_transfer_in',
			},
			{
				id: 'stockTransferOut',
				icon: faFileExport,
				handler: this.onClickHandler.bind(this, 'stock-transfer/out'),
				permission: 'shop_front_stock_transfer_out',
			},
			{
				id: 'password',
				icon: faLock,
				handler: this.onToggleModalPasswordHandler,
			},
			{
				id: 'language',
				icon: faLanguage,
				handler: this.onToggleModalLanguageHandler,
			},
			{
				id: 'remarkTemplate',
				icon: faClipboard,
				handler: this.onClickHandler.bind(this, 'remark-template'),
				permission: 'shop_front_remark_template',
			},
			{
				id: 'userManual',
				icon: faBook,
				handler: this.onClickManualHandler.bind(this),
			},
			{
				id: 'logout',
				icon: faSignOutAlt,
				handler: this.onLogoutHandler.bind(this),
			},
		];
	}

	onClickHandler(link) {
		this.props.history.push(`/app/${link}`);
	}

	onToggleModalLanguageHandler() {
		const { showModalLanguage } = this.state;
		this.setState({
			showModalLanguage: !showModalLanguage,
		});
	}

	onToggleModalPasswordHandler() {
		const { showModalPassword } = this.state;
		this.setState({
			showModalPassword: !showModalPassword,
		});
	}

	onClickManualHandler() {
		window.open('https://hk-pos-shop.europe.com.hk/manual/web.pdf');
	}

	onLogoutHandler(e) {
		e.preventDefault();
		if (confirm(loc.MSGLogoutConfirm)) {
			const user = user || (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
			localStorage.removeItem('user');
			if (user && user.login_url) {
				location.href = `/login/${user.login_url}`;
			} else {
				location.href = '/';
			}
		}
	}

	render() {
		const { showModalLanguage, showModalPassword } = this.state;
		const { loginInfo, currentInfo } = this.props;
		return (
			<Fragment>
				<div className="uk-padding-large">
					<div className={ styles.top }>
						<h1>{ loc.posFullMenu }</h1>
						<p>
							{ loc.currentShop }: { loginInfo.data.warehouse_desc }<br />
							{ loc.user }: { loginInfo.data.user_id }
						</p>
					</div>
					<div className="uk-margin-auto">
						{
							currentInfo.isFetching ? (
								<Spinner />
							) : (
								<div className={ classnames('uk-flex', 'uk-flex-wrap', styles.menu) } data-uk-height-match>
									{
										this.menu.map((menuItem, index) => (
											(
												!menuItem.permission ||
												!(currentInfo && currentInfo.data && currentInfo.data.permissions && currentInfo.data.permissions[menuItem.permission] === ' ')
											) && (
												<FullMenuButton
													key={ `page-full-menu-button-${index}` }
													icon={ menuItem.icon }
													label={ loc[menuItem.id] }
													onClick={ menuItem.handler }
												/>
											)
										))
									}
								</div>
							)
						}
					</div>
					<div className={ classnames('uk-position-absolute', 'uk-position-bottom-right', styles.buildNumber) }>
						{ process.env.ENVIRONMENT !== 'PROD' ? process.env.ENVIRONMENT : '' }
					</div>
				</div>

				<ModalLanguage
					isOpen={ showModalLanguage }
					onToggle={ this.onToggleModalLanguageHandler }
				/>

				<ModalPassword
					isOpen={ showModalPassword }
					onToggle={ this.onToggleModalPasswordHandler }
				/>
			</Fragment>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		loginInfo: state.loginInfo,
		currentInfo: state.currentInfo,
	})
)(PageHome));